<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="formModel.userName" placeholder="请输入用户名" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="真实名称" prop="realName">
          <el-input v-model="formModel.realName" placeholder="请输入真实名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item v-show="ruleValidate.password[0].required" label="密码" prop="password">
          <el-input
            type="password"
            v-model="formModel.password"
            placeholder="请输入密码"
            style="width:300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属单位" prop="companyId">
          <el-select v-model="formModel.companyId" filterable placeholder="请选择" style="width:300px">
            <el-option
              v-for="company in companyResult"
              :key="company.id"
              :label="company.name"
              :value="company.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分配角色" prop="roles">
          <el-select
            v-model="formModel.roles"
            filterable
            multiple
            placeholder="请选择"
            style="width:300px"
          >
            <el-option
              v-for="role in roleList"
              :key="role.id"
              :label="role.description"
              :value="role.id"
            ></el-option>
          </el-select>
          <!-- <el-transfer v-model="formModel.roles" :data="roleList" 
          :props="{key: 'id',label: 'description'}" :titles="['所有角色','已分配角色']"></el-transfer>-->
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import userApi from "@/api/sys/user";
import roleApi from "@/api/sys/role";

export default {
  props: ["businessKey", "title", "companyResult"],
  data() {
    return {
      showPwd: true,
      ruleValidate: {
        userName: [
          { required: true, message: "用户名不能为空", trigger: "blur" }
        ],
        realName: [
          { required: true, message: "真实名称不能为空", trigger: "blur" }
        ],
        companyId: [
          { required: true, message: "单位不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" }
        ],
        roles: [
          { required: true, message: "分配角色不能为空", trigger: "blur" }
        ]
      },
      roleList: [],
      formModel: {},
      showDialog: true,
      loading: false,
      submitting: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return userApi.add(self.formModel);
            } else {
              return userApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close",true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    }
  },
  async mounted() {
    var self = this;
    self.loading = true;

    // 不需要先加载列表值，再加载选中值了
    await roleApi.selectAll().then(response => {
      this.roleList = response.data.data;
    });

    (function() {
      if (self.businessKey != null && self.businessKey.length > 0) {
        self.ruleValidate.password[0].required = false;
        return userApi.edit(self.businessKey);
      } else {
        self.ruleValidate.password[0].required = true;
        return userApi.create();
      }
    })()
      .then(response => {
        var jsonData = response.data;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          self.showModal = true;
        } else {
          self.$message.error(jsonData.message + "");
        }

        self.loading = false;
      })
      .catch(error => {
        self.$message.error(error + "");
        self.loading = false;
      });
  }
};
</script>